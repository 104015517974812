import React, { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { getImageUrl, SanityImage } from 'superclient';
import { Superclient } from '../../clients/superclient';

const MetaModule: FC<{
  title: string;
  path: string;
  description?: string;
  keywords?: string;
  sanityImage?: SanityImage;
  customImage?: {
    src: string;
    width?: number;
    height?: number;
  };
}> = ({ title, path, description, keywords, sanityImage, customImage }) => {
  // Default Share Image
  let width = 1199;
  let height = 626;
  let imageType = 'image/jpeg';
  let imageUrl = 'https://www.supercluster.com/supercluster_share.jpg';

  if (customImage) {
    if (customImage.width) {
      width = customImage.width;
    }
    if (customImage.height) {
      height = customImage.height;
    }
    imageUrl = customImage.src;
  } else if (sanityImage) {
    width = 1200;
    height = 628;
    imageType = sanityImage.asset.mimeType;
    imageUrl = Superclient.getImageUrl(sanityImage, width, height, 2) || '';
  }

  const pageUrl = `https://www.supercluster.com${path}`;

  const defaultKeywords = '';

  const defaultDescription =
    'Supercluster’s mission is to tell the human side of our greatest outer space stories. With films, podcasts, artwork, events, and applications, we’re exploring amazing milestones from our past and the wildest ideas that drive our future.';

  useEffect(() => {
    // Google Analytics
    ReactGA.initialize('UA-130185499-1');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    // Facebook Pixel
    ReactPixel.init('2208482686059820');
    ReactPixel.pageView();
  }, []);

  return (
    <Helmet title={title}>
      <html lang="en" />
      <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Supercluster" />
      <meta name="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description || defaultDescription} />

      <meta name="og:image" content={imageUrl} />
      <meta name="og:image:url" content={imageUrl} />
      <meta name="og:image:secure" content={imageUrl} />
      <meta property="og:image:type" content={imageType} />
      <meta property="og:image:width" content={`${width}`} />
      <meta property="og:image:height" content={`${height}`} />

      <meta name="twitter:site" content="@SuperclusterHQ" />
      <meta name="twitter:creator" content="@SuperclusterHQ" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={title === 'Stories' ? 'Supercluster' : title}
      />
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:image:src" content={imageUrl} />
      <meta name="twitter:image:width" content={`${width}`} />
      <meta name="twitter:image:height" content={`${height}`} />
      <meta name="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default MetaModule;
